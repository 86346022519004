import { ShipperLabel } from 'components';
import React from 'react';
import { format } from "date-fns"
import { getTemplateStopTypeString } from '../../../../services/enums/TemplateStopType'

const Columns = {
  stopNumber: {
    name: 'stopNumber',
    header: ({ t }) => t('stopNumber'),
    body: ({ stop }) => stop.stopNumber
  },
  tourStopId: {
    name: 'tourStopId',
    header: ({ t }) => t('tourStopId'),
    body: ({ stop }) => stop.id
  },
  shipper: {
    name: 'shipper',
    header: ({ t }) => t('shipper'),
    body: ({ stop }) => <ShipperLabel stop={stop} />
  },
  deliveryWindow: {
    name: 'deliveryWindow',
    header: ({ t }) => t('deliveryWindow'),
    body: ({ stop }) => (stop.deliveryWindow && stop.deliveryWindow.from ? format(new Date(stop.deliveryWindow.from), 'HH:mm') : "") + "-" + (stop.deliveryWindow && stop.deliveryWindow.to ? format(new Date(stop.deliveryWindow.to), 'HH:mm') : "")
  },
  stopType: {
    name: 'stopType',
    header: ({ t }) => t('stopType'),
    body: ({ stop, t }) => t(getTemplateStopTypeString(stop.stopType))
  },
  lastName: {
    name: 'lastName',
    header: ({ t }) => t('lastName'),
    body: ({ stop }) => stop.name
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({ t }) => t('streetAndNumber'),
    body: ({ stop }) => stop.address.streetAndNumber
  },
  zipcode: {
    name: 'zipcode',
    header: ({ t }) => t('zipcode'),
    body: ({ stop }) => stop.address.zipcode
  },
  templateStopType: {
    name: "templateStopType",
    header: ({ t }) => t('templateStopType'),
    body: ({ stop }) => stop.templateStopType
  }
}

export default Columns;