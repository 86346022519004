import { displayModes } from '../../../services/enums/displayModes';
import Columns from './columns';

// CARE: ALWAYS INCREMENT THE VERSION IF THE SETTINGS FOR A COLUMN CHANGE!
// Config explanation
// optional: determines if the hidden status can be changed by the user
// hidden: determines if the field is shown or hidden
// conditional: indicates that the hidden status can be overriden using the conditions dictionary of the useTableConfig hook
const DefaultConfig = {
  [displayModes.admin]: {
    version: 2,
    columns: {
      tourId: {
        columnName: Columns.tourId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      carrier: {
        columnName: Columns.carrier.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      microHub: {
        columnName: Columns.microHub.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      numStops: {
        columnName: Columns.numStops.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      durationCar: {
        columnName: Columns.durationCar.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      durationBicycle: {
        columnName: Columns.durationBicycle.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
    }
  },
  [displayModes.carrier]: {
    version: 2,
    columns: {
      tourId: {
        columnName: Columns.tourId.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 1,
      },
      carrier: {
        columnName: Columns.carrier.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 2
      },
      microHub: {
        columnName: Columns.microHub.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 3
      },
      numStops: {
        columnName: Columns.numStops.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 4
      },
      durationCar: {
        columnName: Columns.durationCar.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 5
      },
      durationBicycle: {
        columnName: Columns.durationBicycle.name,
        optional: false,
        hidden: false,
        conditional: false,
        order: 6
      },
    }
  },
  [displayModes.shipper]: {
    version: 1,
    columns: {}
  },
}

export default DefaultConfig;