import React, {Fragment, useEffect, useState} from 'react'
import {Button, Tooltip, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Close as CloseIcon, InfoOutlined as InfoIconSymbol,} from '@material-ui/icons';
import 'moment-duration-format';
import {Portlet, PortletContent, PortletHeader} from 'components';
import {displayShipperAndShipperShortName, roundToFixed} from 'services/util/helperFuncs';
import TourStopTable from './TourStopTable';
import TemplateStopInstanceDetailShow
  from 'components/TemplateStopInstance/TemplateStopInstanceDetail/TemplateStopInstanceDetailShow';
import {DateService} from 'services/util/DateService';
import {displayModes} from 'services/enums/displayModes';

function TourDetail(props) {

  const {
    classes,
    className,
    t,
    tour,
    closeTourDetail,
    displayMode
  } = props;
  const [selectedStop, setSelectedStop] = useState(null);

  const showStopDetails = (stop) => {
    setSelectedStop(stop)
  };

  useEffect(() => {
    tour.templateStopInstances.sort((s1, s2) => (s1.stopNumber > s2.stopNumber) ? 1 : ((s2.stopNumber > s1.stopNumber) ? -1 : 0));
    setSelectedStop(null);
  }, [tour]);

  function getTourDeliveryWindow(tour) {
    const tourStops = tour.templateStopInstances;
    const from = tourStops.length > 0 ? tourStops[0]?.deliveryWindow?.from : null;
    const to = tourStops[tourStops.length - 1]?.deliveryWindow?.to;
    return `${DateService.displayTime(from)} - ${DateService.displayTime(to)}`;
  }

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <Portlet className={rootClassName}>
        <PortletHeader style={{ boxShadow: `inset 10px 0px 0px 0px ${tour.color}` }}>
          <Fragment>
            <div className={classes.buttonContainer}>
              {closeTourDetail &&
                <Button
                  className={classes.button}
                  color="default"
                  onClick={closeTourDetail}
                  title={t('close')}
                  variant="contained"
                >
                  <CloseIcon />
                </Button>
              }
            </div>
          </Fragment>
        </PortletHeader>
        <PortletContent noPadding>
          <Fragment>
            <div className={classes.wrapper}>
              <div className={classes.wrapperChild}>
                <div>
                  <div><strong>{t('generalInformation')}</strong>:</div> 
                  <div className={classes.detail}>
                    <strong>{t('shipper')}</strong>: {displayShipperAndShipperShortName(tour)}</div>
                  <div className={classes.detail}><strong>{t('length')}</strong>: {roundToFixed(tour.lengthKm, 1)}
                    <Tooltip
                      title={<div style={{ whiteSpace: 'pre-wrap' }}>{t('tourLengthDescription')}</div>}
                    >
                      <InfoIconSymbol className={classes.infoIcon} />
                    </Tooltip>
                  </div>
                  <div className={classes.detail}><strong>{t('durationCar')}</strong>: {roundToFixed(tour.durationCarMinutes, 1)}
                    <Tooltip
                      title={<div style={{ whiteSpace: 'pre-wrap' }}>{t('tourDurationCarDescription')}</div>}
                    >
                      <InfoIconSymbol className={classes.infoIcon} />
                    </Tooltip>
                  </div>
                  <div className={classes.detail}><strong>{t('durationBicycle')}</strong>: {roundToFixed(tour.durationBicycleMinutes, 1)}
                    <Tooltip
                      title={<div style={{ whiteSpace: 'pre-wrap' }}>{t('tourDurationBikeDescription')}</div>}
                    >
                      <InfoIconSymbol className={classes.infoIcon} />
                    </Tooltip>
                  </div>
                  <div className={classes.detail}><strong>{t('deliveryWindow')}</strong>: {getTourDeliveryWindow(tour)}</div>
                </div>
                <br />
              </div>
              <div className={classes.wrapperChild}>
                <div>
                  <div><strong>{t('tourAssignment')}</strong>:</div>
                  <div className={classes.detail}><strong>{t('carrier')}</strong>: {tour.carrierName}</div>
                  <div className={classes.detail}><strong>{t('microHub')}</strong>: {tour.microHubName}</div>
                </div>
              </div>

              <div className={classes.wrapperChild}>
                <div>
                  <div><strong>{t('state')}</strong>:</div>
                  <div className={classes.detail}>
                    <strong>{t('planedTourDelivery')}</strong>: {DateService.displayDate(tour.deliveryDate)}
                  </div>
                </div>
              </div>
            </div>
            <TourStopTable
              className={classNames(classes.tourStopTable, rootClassName)}
              displayMode={displayMode}
              selectedStop={selectedStop}
              showStopDetails={showStopDetails}
              tour={tour}
            />
            {selectedStop &&
              <TemplateStopInstanceDetailShow
                className={classNames(classes.tourStopTable, rootClassName)}
                closeDetailView={() => setSelectedStop(null)}
                readOnly
                templateStopInstance={selectedStop}
                includeRelatedTemplateStopInstances={false}
              />
            }
          </Fragment>
        </PortletContent>
      </Portlet>
    </Fragment>
  );
}


TourDetail.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeTourDetail: PropTypes.func,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  tour: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TourDetail);
